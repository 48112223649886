import { CurrentParts } from '@acrelec-cloud/apico-cdk';
import { ProductClassificationObject, ProductPartObject } from '@acrelec-cloud/apico-sdk';
import { observer } from 'mobx-react-lite';
import React, { Fragment } from 'react';

import { FirstItem, useGroupParts } from 'src/components/Products/Parts/hooks/group-parts.hook';
import { PartsGroupDispatcher } from 'src/components/Products/Parts/PartsGroupDispatcher';

import { PartRenderer } from './PartRenderer';

interface PartsDispatcherProps {
  // TODO: change any
  currentPart: any;
  parent?: any;
  firstExtraItem?: FirstItem | undefined;
  firstIncludedItem?: FirstItem | undefined;
  firstRequiredItem?: FirstItem | undefined;
  onToggleCustomization?: (parts: ProductPartObject[] | undefined) => void;
  isModal?: boolean;
}

export const PartsDispatcher = observer(
  ({
    currentPart,
    parent,
    firstExtraItem,
    firstIncludedItem,
    firstRequiredItem,
    onToggleCustomization,
    isModal
  }: PartsDispatcherProps) => {
    const { groupPartsByType, displayGroupTitle } = useGroupParts();
    const firstLevel = currentPart.parentArray.length === 1 || isModal;
    const currentProduct = currentPart.product!;

    const optionsGrouped =
      currentProduct.options &&
      currentProduct.options.length > 0 &&
      groupPartsByType(currentProduct.options);

    const optionsSections = optionsGrouped?.itemsSorted && optionsGrouped.itemsSorted.reduce((items: any[], item: CurrentParts) => {
      const wopGroup = item.product?.customParams && item.product?.customParams.find((customParam: any) => customParam.param_id === "WopGroup");
      // console.log(item.product?.name, wopGroup)
      const itemContainer = items.find((i: any) => i.group === wopGroup?.param_value);
      if (!itemContainer) {
        return [
          ...items,
          {
            group: wopGroup?.param_value,
            parts: [item]
          }
        ]
      }
      itemContainer.parts.push(item);
      return items;
    }, []);

    return (
      <div
        className={`dispatcher${firstLevel ? ' dispatcher--first-level' : ''}`}>
        {displayGroupTitle(currentProduct.code, currentPart.parentArray, {
          required: firstRequiredItem,
          extra: firstExtraItem,
          included: firstIncludedItem,
        })}
        <PartRenderer
          part={currentPart}
          parent={parent}
          onToggleCustomization={(parts) => {
            onToggleCustomization && onToggleCustomization(parts);
          }}
        />
        {/* {optionsGrouped &&
          optionsGrouped.itemsSorted.map((option: ProductClassificationObject) => {
            return (
              <PartsDispatcher
                key={option.product!.id}
                currentPart={option}
                parent={currentPart}
                onToggleCustomization={(parts) => {
                  onToggleCustomization && onToggleCustomization(parts);
                }}
              />
            );
          })} */}
        {optionsSections && optionsSections.map((group: any, index: number) => {
          if (!group.group) {
            return (
              <Fragment key={`nogroup-${index}`}>
                {group.parts.map((option: ProductClassificationObject) => <PartsDispatcher

                  key={`${option.productCode}-${index}`}
                  currentPart={option}
                  parent={currentPart}
                  onToggleCustomization={(parts) => {
                    onToggleCustomization && onToggleCustomization(parts);
                  }}
                />)}
              </Fragment>
            );
          }
          return <PartsGroupDispatcher
            name={group.group}
            items={group.parts}
            key={`${group.group}-${index}`}
            currentPart={currentPart}
            parent={parent}
            onToggleCustomization={onToggleCustomization}
            firstExtraItem={firstExtraItem}
            firstIncludedItem={firstIncludedItem}
            firstRequiredItem={firstRequiredItem}
          ></PartsGroupDispatcher>
        })}
      </div>
    );
  },
);
