import { CurrentParts } from '@acrelec-cloud/apico-cdk';
import { ProductObject } from '@acrelec-cloud/apico-shared';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';

import { useTranslate } from 'src/components/Languages/translate.hook';
import { Modal } from 'src/components/Modal/Modal';
import { useGroupParts } from 'src/components/Products/Parts/hooks/group-parts.hook';
import { PartsDispatcher } from 'src/components/Products/Parts/PartsDispatcher';

export interface ProductCustomizationModalProps {
  currentPart: CurrentParts;
  open: boolean;
  isChildrenCompleted: boolean;
  onClose: () => void;
  part: ProductObject;
  parent: number[];
}

export const InternalProductCustomizationModal = observer(
  ({ open, currentPart, part, parent, onClose, isChildrenCompleted }: ProductCustomizationModalProps) => {
    const { translate } = useTranslate();
    const { groupPartsByType } = useGroupParts();

    const currentProductParts = currentPart.product?.parts

    const { itemsSorted, firstItems } = useMemo(
      () => {
        return groupPartsByType(currentProductParts!);
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [currentProductParts],
    );

    const [lastChildrenState, setLastChildrenState] = useState(isChildrenCompleted)
    useEffect(() => {
      if (open && isChildrenCompleted && !lastChildrenState) {
        onClose()
      }
    }, [isChildrenCompleted]);

    useEffect(() => {
      setLastChildrenState(isChildrenCompleted);
    }, [isChildrenCompleted])

    return (
      <Modal open={open} onClose={onClose} closable={isChildrenCompleted}>
        <div className="modal__product-customization">
          { isChildrenCompleted && <div className="customization-modal__close close" onClick={() => onClose()}></div>}
          <p className="product-customization__title">
            {translate('ProductCustomizationModal.title')}
          </p>
          <div className="customization-center__position">
            <div className="customization-modal__wrapper">
              {itemsSorted &&
                firstItems &&
                itemsSorted.map((currentPart, index) => (
                  <PartsDispatcher
                    isModal
                    currentPart={currentPart}
                    key={`${currentPart.partCode}-${index}`}
                    firstExtraItem={firstItems.extra}
                    firstIncludedItem={firstItems.included}
                    firstRequiredItem={firstItems.required}
                    onToggleCustomization={(parts) => {

                    }}
                  />
                ))}
            </div>
          </div>
          <div className="customization-modal__buttons">
            <button
              className="btn__secondary customization-modal__button"
              disabled={!isChildrenCompleted}
              onClick={() => onClose()}>
              {translate('ProductCustomizationModal.confirm')}
            </button>
          </div>
        </div>
      </Modal>
    );
  },
);
// temp
