import {
  AuthStore,
  AvailableLanguages,
  BasketStore,
  BrandStore,
  CustomizationStore,
  DisplayTypes,
  LanguageStore,
  NavigationStore,
  OrderStore,
  PopoverStore,
  RestaurantStore,
} from "@acrelec-cloud/apico-cdk";
import { ApicoSDK, createMockedSdk } from "@acrelec-cloud/apico-sdk";
import { Instance, applySnapshot, getSnapshot, types } from "mobx-state-tree";
// import store from "store";

import { availableLanguages } from "src/i18n";
import contentsDatabase from "src/mocks/contents.database.json";

export const apicoSDK = process.env.REACT_APP_API_URL
  ? new ApicoSDK({
    endpoint: process.env.REACT_APP_API_URL,
  })
  : createMockedSdk(
    {},
    {
      contents: contentsDatabase as any,
    }
  );

export type RootSdkType = {
  microservice: ApicoSDK;
};

const authStore = AuthStore.create({
  isLogged: false,
});

const basketStore = BasketStore.create({
  products: [],
});

const brandStore = BrandStore.create({
  banners: [],
});

const navigationStore = NavigationStore.create({});

export const firstStateOfCustomizationStore = {
  quantity: 1,
  parts: [],
};

const customizationStore = CustomizationStore.create(
  firstStateOfCustomizationStore
);

const languageStore = LanguageStore.create({
  languages: availableLanguages,
  currentLanguage: process.env
    .REACT_APP_DEFAULT_LANGUAGE! as AvailableLanguages,
});

const popoverStore = PopoverStore.create({
  display: DisplayTypes.CLOSED,
});

const orderStore = OrderStore.create({
  orders: [],
});

const restaurantStore = RestaurantStore.create({
  restaurants: [],
});

export const RootStore = types
  .model("RootStore", {
    auth: AuthStore,
    basket: BasketStore,
    brand: BrandStore,
    navigation: NavigationStore,
    customization: CustomizationStore,
    language: LanguageStore,
    popover: PopoverStore,
    order: OrderStore,
    restaurant: RestaurantStore,
  })
  .actions((self: any) => {
    let initialState: any = {};
    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      reset: (storeName?: string) => {
        if (storeName) {
          applySnapshot(self[storeName], initialState[storeName]);
        } else {
          applySnapshot(self.auth, initialState);
        }
      },
    };
  });

export type RootStoreType = Instance<typeof RootStore>;

export const createStore = () => {
  return RootStore.create(
    {
      auth: authStore,
      basket: basketStore,
      brand: brandStore,
      navigation: navigationStore,
      customization: customizationStore,
      language: languageStore,
      popover: popoverStore,
      order: orderStore,
      restaurant: restaurantStore,
    },
    {
      microservice: apicoSDK,
    } as RootSdkType
  );
};

export const rootStore = createStore();

const store: {
  initialState: any
} = {
  initialState: undefined
}

export const rootSnapshot = (desiredStore: keyof RootStoreType) => {
  return {
    saveInitialState() {
      const initialState = getSnapshot(rootStore[desiredStore]);
      store.initialState = initialState;
    },
    applyInitialState() {
      // If local storage no allow, just return;
      let initialState = store.initialState;
      if (initialState) {
        applySnapshot(rootStore[desiredStore], initialState);
      }
    },
    reset(firstState: any) {
      // If local storage no allow, just return;
      applySnapshot(rootStore[desiredStore], firstState);
    },
  };
};
