import { CurrentParts, CustomPartsType } from '@acrelec-cloud/apico-cdk';
import { ProductPartObject, ProductType } from '@acrelec-cloud/apico-sdk';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';

import { usePartComparator } from 'src/components/Products/Parts/hooks/part-comparator.hook';
import { PartsDispatcher } from 'src/components/Products/Parts/PartsDispatcher';
import { compareParents } from 'src/components/Products/Parts/utils/find-current-part.util';

import { PartRegular } from './PartRegular';

interface PartOptionProps {
  quantity: number;
  parent: CurrentParts;
  product: CurrentParts;
  hasChildren?: boolean;
  onToggleCustomization: (parts: ProductPartObject[] | undefined) => void;
}

export const PartOption = observer(
  ({ quantity, parent, product, hasChildren = false, onToggleCustomization }: PartOptionProps) => {
    const [comparedParts] = usePartComparator();

    const leftover = useMemo(() => {
      if (!parent) return;
      const leftover =
        parent.maxQuantity -
        comparedParts
          .filter((item: CustomPartsType) =>
            compareParents(toJS(item.parent), product.parentArray!),
          )
          .reduce((currentValue: number, item: CustomPartsType) => currentValue + item.qty, 0);
      return leftover > 0 ? leftover : 0;
    }, [parent, comparedParts, product.parentArray]);

    return (
      <>
        <PartRegular
          currentPart={product}
          part={product.product!}
          min={parent.minQuantity}
          max={parent.maxQuantity}
          leftover={leftover}
          parent={product.parentArray!}
          qty={quantity}
          parentType={parent.product?.type}
          hasChildren={hasChildren}
          onToggleCustomization={(parts) => onToggleCustomization(parts)}
        />
        {hasChildren && product!.product! && product!.product!.parts!.filter(currentPart => currentPart.product?.type === ProductType.OPTION).map((currentPart) => (
          <PartsDispatcher
            key={currentPart.partCode}
            currentPart={currentPart}
            parent={product}
            onToggleCustomization={(parts) => {
              onToggleCustomization && onToggleCustomization(parts);
            }}
          />
        ))}
      </>
    );
  },
);
