import persist from 'mst-persist';
import React, {ReactNode, useContext, useEffect, useState} from 'react';
import {createContext} from 'react';

import {rootStore, RootStoreType} from '../stores/root.store';

const StoreContext = createContext<RootStoreType | undefined>(undefined);

export interface StoreProviderProps {
	children: ReactNode;
}

export function StoreProvider(props: StoreProviderProps) {
	const {children} = props;

	const [isLoaded, setIsLoaded] = useState<boolean>(false);

	const checkLocalStorageAvailability = async () => {
		try {
			if (window.localStorage) {
				await persist('auth', rootStore.auth, {
					storage: localStorage,
					jsonify: true,
				})
				await persist('restaurant', rootStore.restaurant, {
					storage: localStorage,
					jsonify: true,
				})
				await persist('basket', rootStore.basket, {
					storage: localStorage,
					jsonify: true,
				})
				await persist('language', rootStore.language, {
					storage: localStorage,
					jsonify: true,
				})
				// await persist('order', rootStore.order, {
				// 	storage: localStorage,
				// 	jsonify: true,
				// })
        rootStore.afterCreate();
				setIsLoaded(true);
			}
		} catch (error) {
			setIsLoaded(true);
		}
	};

	useEffect(() => {
		checkLocalStorageAvailability();
	}, []);

	return isLoaded ? (
		<StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>
	) : (
		<></>
	);
}

export const useStore = () => useContext(StoreContext) as RootStoreType;
