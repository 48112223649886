import {ProductObject} from '@acrelec-cloud/apico-sdk';
import {observer} from 'mobx-react-lite';
import React, {useRef, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {ReactSVG} from 'react-svg';

import arrowIcon from 'src/assets/images/icons/down-chevron.svg';
import unavailableIcon from 'src/assets/images/icons/error-circle.svg';
import searchIcon from 'src/assets/images/icons/search.svg';
import {useTranslate} from 'src/components/Languages/translate.hook';
import {useStore} from 'src/contexts/store.context';

interface SearchResult {
  product: ProductObject;
  isUnavailable: boolean;
}

type SearchEngineProps = {
  mode?: 'list' | 'navbar';
}

export const SearchEngine = observer((props: SearchEngineProps) => {
  const {mode = 'list'} = props;
	const history = useHistory();
	const MIN_CHARS_REQUIRED = 3;
	const DEBOUNCE_FETCH_DELAY = 1;
	const {translate} = useTranslate();
	const [searchValue, setSearchValue] = useState<string>('');
	const [filteredProducts, setFilteredProducts] = useState<SearchResult[]>([]);
	const debounceTimer = useRef<any>();

	const {
		navigation: {fetchProductsByFilter},
    restaurant: {currentRestaurant},
	} = useStore();

	const onValueChanged = (newValue: string) => {
		clearTimeout(debounceTimer.current);
		setSearchValue(newValue);
		debounceTimer.current = setTimeout(async () => {
			if (newValue.length >= MIN_CHARS_REQUIRED) {
        const result: SearchResult[] = [];
        const pulledResults = await fetchProductsByFilter({name: newValue});
        pulledResults.forEach((product: ProductObject) => {
          const isExist = currentRestaurant.outageProducts.find((outage: any) => outage.productCode === product.code);
          result.push({
            product,
            isUnavailable: isExist ? true : false,
          });
        });
        setFilteredProducts(result);
			}
		}, DEBOUNCE_FETCH_DELAY * 1000);
		if (newValue.length < MIN_CHARS_REQUIRED && searchValue.length >= MIN_CHARS_REQUIRED) {
			setFilteredProducts([]);
		}
	};

	const onNavigateProduct = (productCode: number) => {
		setFilteredProducts([]);
    return history.push(`/product/${productCode}`);
	};

	return (
		<div className={`search-engine-wrapper search-engine-wrapper--${mode}`}>
			<div className="search-engine">
				{searchValue.length < MIN_CHARS_REQUIRED && (
				<ReactSVG className="search-icon" src={searchIcon} />
				)}
				<input
					type="text"
					value={searchValue}
					className="search-input"
					placeholder={translate('ProductPage.search')}
					onChange={(e) => onValueChanged(e.target.value)}
					onFocus={() => onValueChanged(searchValue)}
				/>
				{searchValue.length >= MIN_CHARS_REQUIRED && (
					<div className="search-clear__icon" onClick={() => onValueChanged('')} />
				)}
				<div className="search-suggestions__content">
					{filteredProducts.map((slot: SearchResult, index: number) => (
            <div
              className={`suggestion-product__tile ${slot.isUnavailable ? 'suggestion-product__tile--unavailable' : ''}`}
              onClick={!slot.isUnavailable ? () => onNavigateProduct(slot.product.code) : () => null}
              key={index}
            >
              <span>{slot.product.name}</span>
              <ReactSVG className="arrow__icon" src={slot.isUnavailable ? unavailableIcon : arrowIcon} />
            </div>
					))}
				</div>
			</div>
		</div>
	);
});
