import {BasketItemProps} from '@acrelec-cloud/apico-cdk';
import {ProductObject, ProductType} from '@acrelec-cloud/apico-sdk';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useMemo, useState} from 'react';
import {useLocation} from 'react-router';

import {BasketItemBottom} from 'src/components/Basket/BasketItem/BasketItemBottom';
import {BasketItemTop} from 'src/components/Basket/BasketItem/BasketItemTop';
import {useTranslate} from 'src/components/Languages/translate.hook';
import {DeleteItemModal} from 'src/components/Modal/CustomModals/DeleteItemModal';
import {useStore} from 'src/contexts/store.context';

import {LoadingSpinner} from '../../LoadingSpinner/LoadingSpinner';

export const BasketItem = observer((props: BasketItemProps) => {
	const {item} = props;
	const {translateProduct} = useTranslate();
	const location = useLocation();
	const [product, setProduct] = useState<undefined | ProductObject>();
	const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

	const {
		customization: {fetchProduct},
	} = useStore();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	useEffect(() => {
		setIsLoading(true);
		fetchProduct(item.code, false)
			.then((response: ProductObject) => setProduct(response), setIsLoading(false))
			.catch(() => setIsLoading(false));
	}, [fetchProduct, item.code]);

	const displayActionButtons = useMemo(() => {
		if(!product) return false;
		return !location.pathname.includes('checkout') || product.type === ProductType.COUPON;
	}, [location, product]);
	
	return (
		<>
			{product && (
				<>
					{isLoading ? (
						<div className="loading-div">
							<LoadingSpinner />
						</div>
					) : (
						<div className="basket-item">
							<BasketItemTop
								id={item.id}
								calories={product.calories}
								type={product.type}
								displayActionButtons={displayActionButtons}
								image={product.imageUrl}
								name={translateProduct(product).name}
								parts={item.partsList}
								quantity={item.quantity}
								modalState={[modalIsOpen, setModalIsOpen]}
							/>
							<BasketItemBottom
								id={item.id}
								code={product.code}
								totalPrice={item.totalPrice}
								quantity={item.quantity}
								displayActionButtons={displayActionButtons}
								modalState={[modalIsOpen, setModalIsOpen]}
								type={product.type}
								item={item}
							/>
							<DeleteItemModal
								open={modalIsOpen}
								onClose={() => setModalIsOpen(false)}
								itemId={item.id}
							/>
						</div>
					)}
				</>
			)}
		</>
	);
});
