import { ProductClassificationObject, ProductPartObject } from '@acrelec-cloud/apico-sdk';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { FirstItem, useGroupParts } from 'src/components/Products/Parts/hooks/group-parts.hook';
import { PartsDispatcher } from 'src/components/Products/Parts/PartsDispatcher';
import { ToggleButton } from 'src/components/Products/Parts/ToggleButton';

interface PartsGroupDispatcherProps {
  // TODO: change any
  name: string;
  items: ProductClassificationObject[];
  currentPart: any;
  parent?: any;
  firstExtraItem?: FirstItem | undefined;
  firstIncludedItem?: FirstItem | undefined;
  firstRequiredItem?: FirstItem | undefined;
  onToggleCustomization?: (parts: ProductPartObject[] | undefined) => void;
}

export const PartsGroupDispatcher = observer(
  ({
    name,
    items,
    currentPart,
    parent,
    firstExtraItem,
    firstIncludedItem,
    firstRequiredItem,
    onToggleCustomization,
  }: PartsGroupDispatcherProps) => {
    const currentProduct = currentPart.product!;

    const firstItem = items[0];

    return (
      <div
        className={`dispatcher`}>

        <div className={`parts__item`}>
          <div
            className={`parts__title-wrap`}>
            <img src={firstItem.product?.imageUrl} alt=" " className="parts__image" />
            <p className="parts__name txt-s txt-bold txt-secondary-dark">{name}</p>
            <ToggleButton
              display
              titleOnly
              isOption
            />
          </div>
        </div>
        {items.map((option: ProductClassificationObject) => {
          return (
            <PartsDispatcher
              key={option.product!.id}
              currentPart={option}
              parent={currentPart}
              onToggleCustomization={(parts) => {
                onToggleCustomization && onToggleCustomization(parts);
              }}
            />
          );
        })}
      </div>
    );
  },
);
