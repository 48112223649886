import {
  findPrice,
  findAddPrice,
  computedPrices,
} from "@acrelec-cloud/apico-cdk";
import { ProductObject } from "@acrelec-cloud/apico-sdk";

export const calculatePartPrice = (part: ProductObject): number => {
  const addedPrice = findAddPrice(part);
  const defaultUnitPrice = findPrice(part);
  const partPrice = addedPrice > 0 ? addedPrice : defaultUnitPrice;

  return computedPrices(part.tax, partPrice).priceExcTax;
};
