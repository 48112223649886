export const scrollTo = (
  direction: "bottom" | "top",
  destinyElement?: HTMLDivElement,
  offset: number = 0
) => {
  const destiny = destinyElement
    ? destinyElement
    : document.getElementsByTagName("main")[0];

  const scrollTo = {
    bottom: () => {
      const y = destiny.getBoundingClientRect().bottom - window.pageYOffset - offset;
      window.scrollTo({top: y, behavior: 'smooth'});
    },
    top: () => {
      const y = destiny.getBoundingClientRect().top + window.pageYOffset + offset;
      window.scrollTo({top: y, behavior: 'smooth'});
    },
  };
  setTimeout(() => scrollTo[direction](), 200);
};
