import { OrderObject } from "@acrelec-cloud/apico-sdk";

interface PaymentVerificationsProps {
  checkBasketPrice: (
    order: OrderObject,
    basketTotal: number,
    basketTaxes: number,
  ) => boolean | Promise<any>;
}

export const usePaymentVerifications = (): PaymentVerificationsProps => {
  const checkBasketPrice = (
    order: OrderObject,
    basketTotal: number,
    basketTaxes: number,
  ): boolean | Promise<any> => {

    // FIXME: TEMPORARY SOLUTION, NEED TO REMOVE IT 
    const samePrice = (order.price + order.taxes).toFixed(2) === (basketTotal + basketTaxes).toFixed(2);
    // const samePrice = order.price === basketTotal && order.taxes === basketTaxes;

    if (!samePrice) {
      throw new Error('incompatible_price');
    }

    return samePrice;
  };

  return { checkBasketPrice };
};
