import {ProductPartObject} from '@acrelec-cloud/apico-sdk';
import {toJS} from 'mobx';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useMemo, useState} from 'react';

import {useTranslate} from 'src/components/Languages/translate.hook';
import {LoadingSpinner} from 'src/components/LoadingSpinner/LoadingSpinner';
import {ProductCustomizationModal} from 'src/components/Modal/CustomModals/ProductCustomizationModal';
import {useGroupParts} from 'src/components/Products/Parts/hooks/group-parts.hook';
import {useStore} from 'src/contexts/store.context';

import {useCustomization} from '../customization.hook';
import {PartsDispatcher} from './PartsDispatcher';
export const PartsContainer = observer(() => {
	const {translate} = useTranslate();
	const {groupPartsByType} = useGroupParts();
	const [isFirstLoading, setIsFirstLoading] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [showCustomization, setShowCustomization] = useState(false);
	const [currentParts, setCurrentParts] = useState<ProductPartObject[]>();

	const {
		customization: {currentProduct, currentProductParts},
	} = useStore();
	const {resetCustomization} = useCustomization();
	const handleReset = (): void => {
		resetCustomization(currentProduct.code ? currentProduct.code : 0);
	};

	const {itemsSorted, firstItems} = useMemo(
		() => {
			setIsLoading(true);
			return groupPartsByType(toJS(currentProductParts!));
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[currentProductParts],
	);

	useEffect(() => {
		if (itemsSorted.length && itemsSorted[0].productCode !== currentProduct.code) {
			setIsLoading(true);
		} else {
			setIsLoading(false);
      setIsFirstLoading(false);
		}
	}, [itemsSorted, firstItems, currentProduct.code]);
  
	return (
		<div className="parts-container">
			{isLoading && isFirstLoading ? (
				<div className="loading-parts">
					<LoadingSpinner />
				</div>
			) : (
				<div className="parts">
					<ProductCustomizationModal
						open={showCustomization}
						onClose={() => setShowCustomization(false)}
						parts={currentParts}
					/>

					<div className="parts__header">
						<h2 className="parts__title txt-h2 txt-grey-80 txt-uppercase">
							{translate('Parts.parts')}
						</h2>
						<button
							className="parts__reset btn__secondary--mini-inverse btn__secondary--icon-after btn__secondary--icon-fas btn-padding bg-secondary"
							theme-icon=""
							onClick={() => handleReset()}>
							{translate('Parts.reset')}
						</button>
					</div>
					<div className="parts__list">
						{itemsSorted &&
							firstItems &&
							itemsSorted.map((currentPart, index) => (
								<PartsDispatcher
									currentPart={currentPart}
									key={`${currentPart.partCode}-${index}`}
									firstExtraItem={firstItems.extra}
									firstIncludedItem={firstItems.included}
									firstRequiredItem={firstItems.required}
									onToggleCustomization={(parts) => {
										setCurrentParts(parts);
										setShowCustomization(true);
									}}
								/>
							))}
					</div>
				</div>
			)}
		</div>
	);
});
