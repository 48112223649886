import {OrderObject, StripePaymentObject} from '@acrelec-cloud/apico-sdk';
import {CardElement, useElements, useStripe} from '@stripe/react-stripe-js';
import { observer } from 'mobx-react-lite';
import React, {useRef, useState} from 'react';

import {DataProtectionCheckbox} from 'src/components/Form/DataProtectionCheckbox';
import {PhoneFormInput} from 'src/components/Form/PhoneFormInput';
import {emailPattern, phonePattern} from 'src/components/Form/validator-form.config';
import {useTranslate} from 'src/components/Languages/translate.hook';
import {IncompatiblePriceModal} from 'src/components/Order/PaymentModals/IncompatiblePriceModal';
import {PaymentStatusModal} from 'src/components/Order/PaymentModals/PaymentStatusModal';
import {useStore} from 'src/contexts/store.context';
import {useOpeningHours} from 'src/hooks/opening-hours.hook';

import {PaymentFormInput} from '../../Form/PaymentFormInput';
import {LoadingSpinner} from '../../LoadingSpinner/LoadingSpinner';
import {useCustomization} from '../../Products/customization.hook';
import {cardElementStyle} from '../checkout-form.config';
import {usePaymentVerifications} from '../payment-verifications.hook';

export const CheckoutFormStripeContent = observer(() => {
	const {translate} = useTranslate();
	const {checkBasketPrice} = usePaymentVerifications();
	const elements = useElements();
	const stripe = useStripe();
	const {resetCustomization} = useCustomization();
	const {checkPickupTime} = useOpeningHours();

	const [priceModalIsOpen, setPriceModalIsOpen] = useState<boolean>(false);
	const [paymentModalIsOpen, setPaymentModalIsOpen] = useState<boolean>(false);
	const paymentSuccess = useRef<boolean>(false);
	const [newOrder, setNewOrder] = useState<number | undefined>();
	const [protectionPolicy, setProtectionPolicy] = useState<boolean>(false);
	const [offersMessage, setOffersMessage] = useState<boolean>(false);
	const [paymentError, setPaymentError] = useState<string | undefined>(undefined);
	const [isPaymentLoading, setIsPaymentLoading] = useState<boolean>(false);

	const {
		auth: {profile, createGuest, guest, updateProfile},
		basket: {customerName, setCustomerName, basketTotalWithAllDiscounts},
		order: {createOrder, createStripePayment, findOrder},
	} = useStore();

	const handlePayment = async (userData: {
		email: string;
		firstName: string;
		lastName: string;
		phoneNumber?: string;
  }) => {
		const cardElement = elements?.getElement(CardElement);
		// Show loaiding spinner while processing payment
		setIsPaymentLoading(true);

		if (!stripe || !cardElement) {
			return;
		}

		// TODO: use custom hook / abstract payments method to have single resposibility / Make this handlePayment open to extension and close to change.
		const payWithCard = function (clientSecret: string) {
			const user = profile || guest;
			stripe
				.confirmCardPayment(clientSecret, {
					receipt_email: user?.email,
					payment_method: {
						card: cardElement,
						billing_details: {
							name: customerName,
						},
					},
				})
				.then((response) => {
					const {error} = response;
					if (error) {
						console.error('STRIPE ERROR', error);
						if (error?.code === 'parameter_invalid_empty') {
							// Error param comes from stripe. It will be 'billing_details[phone]' for phone error and 'billing_details[name]' for name error.
							setPaymentError(translate(`StripeError.${error.param}`));
						} else {
							setPaymentError(error?.message);
						}
						setIsPaymentLoading(false);
					} else {
						paymentSuccess.current = true;
						setPaymentModalIsOpen(true);
					}
				})
				.catch((error) => {
					throw new Error('PAYMENT FAILED');
				});
		};

		const createNewOrder = async () => {
			if (newOrder) {
				const order = findOrder(newOrder);
				return order;
			}
			return createOrder(userData, false)
				.then((newOrder: OrderObject) => {
					const {id} = newOrder;
					setNewOrder(id);
					return newOrder;
				})
				.catch((error: unknown) => {
					throw error;
				});
		};

		createNewOrder()
			.then((order: OrderObject) => {
				// FIXME: TEMPORARY SOLUTION, NEED TO REMOVE IT
				checkBasketPrice(order, basketTotalWithAllDiscounts, 0);
				// checkBasketPrice(order, basketSubTotal, computedTaxes);
				return order.id;
			})
			.then((orderId: number) => {
				resetCustomization();
				return createStripePayment(orderId);
			})
			.then((paymentResponse: StripePaymentObject) => {
				if (paymentResponse.paymentMethodId) {
					paymentSuccess.current = true;
					setPaymentModalIsOpen(true);
					return;
				}
				return payWithCard(paymentResponse.paymentIntentClientSecret!);
			})
			.catch((error) => {
				if (error.message === 'incompatible_price') {
					setPriceModalIsOpen(true);
				} else {
					paymentSuccess.current = false;
					setPaymentModalIsOpen(true);
				}
				setIsPaymentLoading(false);
				console.error(error);
			});
	};

  const pay = async (event: any) => {
		event.preventDefault();
		checkPickupTime();
		setIsPaymentLoading(true);

		const customerName = profile
			? `${profile.firstName} ${profile.lastName}`
			: `${event.target.first_name.value} ${event.target.last_name.value}`;
		setCustomerName(customerName);

		// User/Guest already logged
		if (profile || guest) {
			// if User/Guest filled the phone number input, update his data
			if (profile && !profile.phoneNumber) {
				updateProfile({phoneNumber: event.target.phone_number.value}).then(() =>
					handlePayment(profile),
				);
			} else {
				handlePayment(profile ? {...profile} : {
					firstName: event.target.first_name.value,
					lastName: event.target.last_name.value,
					email: event.target.email.value,
					phoneNumber: event.target.phone_number.value,
				});
			}
		} else {
			// New identity for the payment
			createGuest(
				event.target.email.value,
				event.target.first_name.value,
				event.target.last_name.value,
				protectionPolicy,
				offersMessage,
				event.target.phone_number.value,
			).then((newGuest: GuestObject) => {
				handlePayment(newGuest);
			});
		}
	};

	return (
		<>
			<form className="checkout-form checkout__info" onSubmit={pay}>
				{profile && !profile.phoneNumber && (
					<div className="checkout-page-left__content-wrapper select-hour__wrapper content-wrapper--padding">
						<h2 className="txt-h2">{translate('CheckoutPage.payment_method_title.guest')}</h2>
						<PhoneFormInput
							name="phone_number"
							label="CheckoutPage.phone_number.label"
							type="tel"
							placeholder="CheckoutPage.firstname.placeholder"
							pattern={phonePattern}
						/>
					</div>
				)}
				<div className="checkout-page-left__content-wrapper select-hour__wrapper content-wrapper--padding">
					{profile ? (
						<h2 className="txt-h2">{translate('CheckoutPage.payment_method_title.user')}</h2>
					) : (
						<h2 className="txt-h2">{translate('CheckoutPage.payment_method_title.guest')}</h2>
					)}
					{!profile && (
						<>
							<PaymentFormInput
								name="first_name"
								label="CheckoutPage.firstname.label"
								type="text"
								placeholder="CheckoutPage.firstname.placeholder"
								isRequired={true}
							/>
							<PaymentFormInput
								name="last_name"
								label="CheckoutPage.lastname.label"
								type="text"
								placeholder="CheckoutPage.lastname.placeholder"
								isRequired={true}
							/>
							<PaymentFormInput
								name="email"
								label="CheckoutPage.email.label"
								type="email"
								pattern={emailPattern}
								placeholder="CheckoutPage.email.placeholder"
								isRequired={true}
							/>
							<PaymentFormInput
								name="phone_number"
								label="CheckoutPage.phone_number.label"
								type="tel"
								placeholder="CheckoutPage.phone_number.placeholder"
								pattern={phonePattern}
							/>
						</>
					)}
					{!profile && (
						<DataProtectionCheckbox
							protectionPolicy={protectionPolicy}
							setProtectionPolicy={setProtectionPolicy}
							offersMessage={offersMessage}
							setOffersMessage={setOffersMessage}
						/>
					)}
					<div className="form-input__container form-input__container--stripe">
						<CardElement options={cardElementStyle} />
					</div>
					<div className="form-input__action">
						<button
							className="checkout-form__button btn__primary"
							disabled={isPaymentLoading ? true : false}>
							{isPaymentLoading ? (
								<LoadingSpinner height={'16px'} width={'16px'} />
							) : (
								translate('CheckoutPage.pay')
							)}
						</button>
					</div>

					{/* <div className="form-input__container form__terms">
						<p className="txt-italic txt-s txt-center">
							{profile
								? translate('CheckoutPage.legal_message.user') + ' '
								: translate('CheckoutPage.legal_message.guest') + ' '}
							<span
								onClick={() => window.open(process.env.REACT_APP_TERM_OF_USE_PAGE, '_blank')}
								className="form__terms--link">
								{translate('CheckoutPage.terms_of_use')}
							</span>
						</p>
					</div> */}
				</div>
				{paymentError && <p className="txt-error form-input__container--error">{paymentError}</p>}
				{/* <div className="form-input__action">
					<button className="checkout-form__button btn__primary" disabled={!stripe}>
						{isPaymentLoading ? (
							<LoadingSpinner height={'16px'} width={'16px'} />
						) : (
							translate('CheckoutPage.pay')
						)}
					</button>
				</div> */}
				{/* <div className="form-input__container form__terms">
					<p className="txt-italic txt-s">
						{profile
							? translate('CheckoutPage.legal_message.user') + ' '
							: translate('CheckoutPage.legal_message.guest') + ' '}
						<span
							onClick={() => window.open(process.env.REACT_APP_TERM_OF_USE_PAGE, '_blank')}
							className="form__terms--link">
							{translate('CheckoutPage.terms_of_use')}
						</span>
					</p>
				</div> */}
			</form>
			<PaymentStatusModal
				success={paymentSuccess.current}
				open={paymentModalIsOpen}
				onClose={() => setPaymentModalIsOpen(false)}
			/>
			<IncompatiblePriceModal open={priceModalIsOpen} onClose={() => setPriceModalIsOpen(false)} />
		</>
	);
});
